import { Utils } from 'formiojs';
import { keys } from 'lodash';
import { FileActions } from 'src/formio/components/FileComponent/types';
import { form as template } from 'src/formio/templates/file/form';
import { verifyAllowedFileActions } from 'src/formio/templates/file/utils';

const compiledForm = Utils.Evaluator.template(template, 'file-component');
export const formioFileComponentCustomTemplate = (ctx: any) => {
    const allowControlAction = keys(ctx?.component?.attributes || {})?.includes(
        'controlActions',
    );
    ctx.getAllowedFileActions = allowControlAction
        ? verifyAllowedFileActions
        : // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (a: any) => verifyAllowedFileActions([FileActions.DELETE]);

    return compiledForm(ctx);
};
