import { FileActions } from 'src/formio/components/FileComponent/types';

export const verifyAllowedFileActions = (
    fileActions: FileActions[],
): { canDelete: boolean; canDownload: boolean } => {
    const canDelete = [fileActions?.includes(FileActions.DELETE)].some((i) =>
        Boolean(i),
    );

    return {
        canDelete,
        canDownload: fileActions?.includes(FileActions.DOWNLOAD),
    };
};
